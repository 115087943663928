import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useTable, usePagination, useSortBy } from "react-table";
import { COLUMNS_CATEGORY } from "../utils/Col.js";
import { Dropdown } from "react-bootstrap";
import "../styles/table.css";
import Addcategroy from "./Addcategroy.js";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { url } from "../utils/urls.js";

export default function Categories(props) {
  const nav = useNavigate();
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("authToken")
      ? localStorage.getItem("authToken" + "")
      : ""
  );
  const [session_id, setSession_id] = useState(
    localStorage.getItem("session_id")
      ? localStorage.getItem("session_id" + "")
      : ""
  );
  const [categories, setCategories] = useState([]);
  const [string, setString] = useState("");
  const [popup, setPopup] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [previosData, setPreviosData] = useState(undefined);
  const [relatedPcategory, setRelatedPCategory] = useState();
  const fetchdata = async () => {
    toast("Categories loading please wait", {
      progress: true,
    });
    const response = await axios
      .get(`${url}/v1/category/get/all`, {
        headers: { authtoken: authToken, sessionid: session_id },
      })
      .catch((err) => {
        toast.dismiss();
        toast("request failed", {
          type: "error",
        });
        console.log(err);
      });
    toast.dismiss();
    function flattenArray(arr) {
      // console.log(arr);
      let flatArray = [];
      arr.map((obj) => {
        // console.log(obj);
        if (obj.childCategories.length > 0) {
          flatArray.push(obj);
          obj.childCategories.map((item) => {
            if (item.sub_categories.length > 0) {
              flatArray.push(item);
              item.sub_categories.map((e) => {
                flatArray.push(e);
              });
            } else {
              return flatArray.push(item);
            }
          });
        } else {
          return flatArray.push(obj);
        }
      });
      return flatArray;
    }
    // console.log(response.data.data);
    const simpleArray = flattenArray(response.data.data);
    // console.log(simpleArray);
    const resdata = simpleArray.sort((p1, p2) =>
      p1.createdAt < p2.createdAt ? 1 : p1.createdAt > p2.createdAt ? -1 : 0
    );
    setCategories(resdata);
    setApiData(resdata);
    setPageSize(20);
  };
  const handleString = (e) => {
    // console.log(e.target.value);
    setString(e.target.value);
    if (e.target.value === "") {
      setCategories(apiData);
    }
  };

  function search(string, e) {
    e.preventDefault();
    console.log(string);
    if (string) {
      const filtered = categories?.filter((item) => {
        console.log(item);
        return item.des?.toLowerCase().includes(string.toLowerCase());
      });
      setCategories(filtered);
      // setString("");
    }
  }

  // console.log(categories);

  // console.log(simpleArray);

  useEffect(() => {
    fetchdata();
    props.setName("Categories");
    setString("");
  }, []);
  const data = useMemo(() => categories, [categories]);
  const columns = useMemo(() => COLUMNS_CATEGORY, []);
  const tableinstance = useTable({ columns, data }, useSortBy, usePagination);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageOptions,
    state,
    pageCount,
    prepareRow,
  } = tableinstance;
  const { pageIndex } = state;
  return (
    <div className="page">
      <div className="content">
        <div className="add-link">
          <div className="category">
            <button
              className="cat-popup page-big-headings"
              onClick={() => {
                setPopup(true);
                setPreviosData("");
              }}
            >
              Add new Category
            </button>
            <Addcategroy
              trigger={popup}
              setTrigger={setPopup}
              previosData={previosData}
              relatedPcategory={relatedPcategory}
              categories={categories}
              setPreviosData={setPreviosData}
            />
            <span className="med-font">Add new categories</span>
          </div>
        </div>
        <div className="categories-data">
          <div className="categories">
            <div>
              <span className="bigger-font">Categories</span>
            </div>
            <div className="search-div">
              <input
                type="search"
                className="search-category"
                placeholder="Search"
                id="string"
                name="string"
                value={string}
                onChange={handleString}
              />
              <div>
                <button
                  className="search-btn"
                  onClick={(e) => search(string, e)}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="table">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "🔽"
                            : "🔼"
                          : ""}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => {
                        setPopup(true);
                        const data = categories.find(
                          (item) => item.des == row.values.des
                        );
                        setPreviosData(data);
                        console.log(data);
                        if (data?.parentCategory) {
                          const findparent = categories.find((item) => {
                            return item.cid === data.parentCategory;
                          });
                          console.log(findparent);
                          // setRelatedPCategory({
                          //   value: filterparent.cid,
                          //   label: filterparent.des,
                          // });
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="pagination-btns">
              <button
                onClick={() => gotoPage(0)}
                className={pageIndex > 0 ? "ablepagibtn" : "pagination-btn"}
              >
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                className={pageIndex > 0 ? "ablepagibtn" : "pagination-btn"}
              >
                Prev{" "}
              </button>
              <span className="pagination-btn">
                {pageIndex + 1} of {pageOptions.length}
              </span>
              <button
                onClick={() => nextPage()}
                className={
                  pageIndex + 1 < pageCount ? "ablepagibtn" : "pagination-btn"
                }
              >
                Next
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                className={
                  pageIndex + 1 < pageCount ? "ablepagibtn" : "pagination-btn"
                }
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
