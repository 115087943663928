import React, { useState, useEffect } from "react";
import "../styles/addforms.css";
import CreatableSelect from "react-select";
import imageicon from "../assets/Imageicon.png";
import axios from "axios";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Productcategory from "./Productcategory.js";
import { useNavigate } from "react-router-dom";
import backicon from "../assets/back.png";
import Rating from "react-rating-stars-component";
import { url } from "../utils/urls.js";
import { format } from "date-fns";

const tax = [
  {
    label: "abc",
    value: "abc",
  },
  {
    label: "efg",
    value: "efg",
  },
  {
    label: "hij",
    value: "hij",
  },
];

const tax_class = [
  {
    label: "abc",
    value: "abc",
  },
  {
    label: "efg",
    value: "efg",
  },
  {
    label: "hij",
    value: "hij",
  },
];

export default function Addproduct(props) {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  useEffect(() => {
    setstore_id(props.storeId);
  }, [props.storeId]);
  const location = useLocation();
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("authToken")
      ? localStorage.getItem("authToken" + "")
      : ""
  );
  const [session_id, setSession_id] = useState(
    localStorage.getItem("session_id")
      ? localStorage.getItem("session_id" + "")
      : ""
  );
  const [previousData, setPreviousData] = useState(
    location.state ? location.state?.data : ""
  );

  const [previousDataProductId, setPreviousDataProductId] = useState(
    location.state ? location.state?.data?.pid : ""
  );
  console.log(previousData);
  console.log(previousDataProductId);
  const [tax_classs, setTax_class] = useState(
    previousData?.tax_class
      ? {
          value: previousData.tax_class,
          label: previousData.tax_class,
        }
      : ""
  );
  const [taxx, setTaxx] = useState(
    previousData?.tax_status
      ? {
          value: previousData.tax_status,
          label: previousData.tax_status,
        }
      : ""
  );

  const [linkP, setlinkP] = useState([]);
  const [linked_product, setLinkedProduct] = useState([]);
  const [store_id, setstore_id] = useState("");
  const [picture, setPicture] = useState("");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState({
    mainCategory: [],
    childCategory: [],
    childSubCategory: [],
  });
  const [discounts, setDiscounts] = useState({
    type: previousData?.discounts?.[0]?.type
      ? previousData?.discounts?.[0]?.type
      : "",
    title: previousData?.discounts?.[0]?.discounttitle
      ? previousData?.discounts?.[0]?.discounttitle
      : "",
    rate: previousData?.discounts?.[0]?.rate
      ? previousData?.discounts?.[0]?.rate
      : "",
    store_id: previousData?.discounts?.[0]?.store_id
      ? previousData?.discounts?.[0]?.store_id
      : previousData?.store_id
      ? previousData?.store_id
      : "",
    startDateTime: previousData?.discounts?.[0]?.startDateTime
      ? previousData?.discounts?.[0]?.startDateTime
      : "",
    endDateTime: previousData?.discounts?.[0]?.endDateTime
      ? previousData?.discounts?.[0]?.endDateTime
      : "",
    ...(previousData?.discounts?.[0]?.discount_id
      ? { discount_id: previousData?.discounts?.[0]?.discount_id }
      : {}),
    level: "product",
    product_id: "",
  });
  const discountTypes = [
    { label: "fixed", value: "fixed" },
    { label: "percentage", value: "percentage" },
  ];

  const [product, setProduct] = useState({
    store_id: previousData?.store_id || props?.storeId || null,
    categories: previousData?.categories || "",
    product_id: previousData?.product_id || "",
    barcode: previousData?.barcode || "",
    title: previousData?.title || "",
    price: previousData?.price || null,
    stock: previousData?.stock || null,
    color: previousData?.color || "",
    weight: previousData?.weight || null,
    dimension: previousData?.dimension || null,
    sku_number: previousData?.sku_number || "",
    description: previousData?.description || "",
    img_id: previousData?.img_id || "",
    tax_status: previousData?.tax_status || "",
    tax_class: previousData?.tax_class || "",
  });

  const [cat, setCat] = useState(
    product?.categories ? product.categories.split(",").filter(Boolean) : []
  );

  useEffect(() => {
    fetchdata();
  }, [previousData]);

  const fetchdata = async () => {
    const response = await axios
      .get(`${url}/v1/store/product/get?store_id=${props.storeId}`, {
        headers: { authtoken: authToken, sessionid: session_id },
      })

      .catch((err) => console.log(err));
    if (response) {
      const resdata = response.data.products;
      // console.log(resdata[0]);
      setProducts(
        resdata.map((e) => {
          return { value: e.pid, label: e.title };
        })
      );
    }
    if (previousData) {
      const res = await axios
        .get(`${url}/v1/store/product/link?pid=${previousData.pid}`, {
          headers: { authtoken: authToken, sessionid: session_id },
        })
        .catch((err) => console.log(err));
      if (res) {
        const linkdata = res.data.data.map((item) => {
          return { value: item.pid, label: item.title };
        });
        // console.log(linkdata);
        setlinkP(linkdata);
      }
    }
  };

  function handlePicture(event) {
    event.preventDefault();
    setPicture(event.target.files[0]);
  }

  const handleProductChange = (e, key) => {
    // console.log(e, key);
    setProduct({
      ...product,
      [key?.name || e?.target?.name || key]:
        e?.value || e?.target?.files?.[0] || e?.target?.value || "",
    });
  };

  const handleArrayEvent = (e, key) => {
    //   console.log(e, key);
    setLinkedProduct(e.map((item) => item));
  };

  const handleDisocunt = (e, key) => {
    setDiscounts({
      ...discounts,
      [key?.name || e?.target?.name || key]:
        e?.value || e?.target?.files?.[0] || e?.target?.value || "",
    });
  };

  async function linkedSubmit(pid) {
    try {
      // console.log(linked_product);
      const formData = linked_product?.map((item) => {
        return {
          storeID: store_id,
          parentId: pid,
          siblingId: item.value,
        };
      });
      console.log(formData);
      const response = await axios.post(
        `${url}/v1/store/product/link`,
        formData,
        { headers: { authtoken: authToken, sessionid: session_id } }
      );
      console.log(response);
      toast.dismiss();
      toast("link Products Added", {
        type: "success",
      });
    } catch (error) {
      toast.dismiss();
      toast(error.response?.data?.error || "link Products Not Added", {
        type: "error",
      });
      console.error(error);
    }
  }

  async function discountSubmit(pid) {
    try {
      discounts.product_id = pid;
      const formData = discounts;
      let disUrl = discounts.discount_id
        ? `${url}/v1/discount/update`
        : `${url}/v1/admin/create/discount`;

      const response = await axios.post(`${disUrl}`, formData, {
        headers: { authtoken: authToken, sessionid: session_id },
      });
      console.log(response);
      toast.dismiss();
      toast("discount Added", {
        type: "success",
      });
    } catch (error) {
      toast.dismiss();
      // toast(error.response?.data?.error || "discount Not Added", {
      //   type: "error",
      // });
      console.error(error);
    }
  }

  async function formSubmit(formData) {
    console.log(formData);
    try {
      const Newurl = previousData
        ? `${url}/v1/store/product/update?pid=${previousDataProductId}`
        : `${url}/v1/store/product/create`;

      const response = await axios.post(Newurl, formData, {
        headers: { authtoken: authToken, sessionid: session_id },
      });
      console.log(response.data.data);

      if (linked_product) {
        linkedSubmit(previousDataProductId);
      }
      if (product.price - discounts.rate > 1) {
        discountSubmit(previousDataProductId);
      }
      console.log(response);
      toast.dismiss();
      toast("Product updated", {
        type: "success",
      });
    } catch (error) {
      toast.dismiss();
      toast(error.response?.data?.error || "request failed", {
        type: "error",
      });
      console.error(error);
    }
  }

  async function handleSubmit(event) {
    toast("request loading please wait", {
      progress: true,
    });
    event.preventDefault();
    try {
      if (picture) {
        const pictureData = new FormData();
        pictureData.append("media", picture);
        await axios
          .post(`${url}/v1/store/media/upload`, pictureData, {
            headers: {
              authtoken: authToken,
              sessionid: session_id,
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            console.log(response);
            product.img_id = "sellers/" + response.data.fileKey;
            console.log(response.data);
            // const formData = {
            //   product,
            //   mainCategory: categories.mainCategory,
            //   childCateogry: categories.childCategory,
            //   childSubCategory: categories.childSubCategory,
            // };

            const formData = {
              ...product,
              mainCategory: categories.mainCategory,
              childCateogry: categories.childCategory,
              childSubCategory: categories.childSubCategory,
            };
            console.log(formData);
            formSubmit(formData);
          })
          .catch(function (error) {
            toast.dismiss();
            toast(error.response.data || "request failed picture", {
              type: "error",
            });
            console.log(error);
          });
      } else {
        const formData = {
          ...product,
          mainCategory: categories.mainCategory,
          childCateogry: categories.childCategory,
          childSubCategory: categories.childSubCategory,
        };
        formSubmit(formData);
      }
    } catch (error) {
      toast("request failed", {
        type: "error",
      });
      console.error(error);
    }
  }

  const customColor = (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "rgba(239, 241, 249, 0.6)",
    minHeight: "52px",
  });

  return (
    <div className="content">
      <Productcategory
        trigger={popup}
        setCategories={setCategories}
        categories={categories}
        setCat={setCat}
        setTrigger={setPopup}
      />
      <form className="adding-form">
        <div className="form-header">
          <div className="back">
            <button className="back-btn" onClick={() => navigate("/products")}>
              <img src={backicon} alt="back" className="back-icon" />
            </button>
          </div>
          <span className="form-heading">
            {previousData.title || "Add Product"}
          </span>
          <button onClick={handleSubmit} className="submit-btn addp">
            Update
          </button>
        </div>
        {previousData ? (
          <div className="form-header" stars style={{ paddingLeft: "35px" }}>
            <Rating
              count={5} // the number of
              value={previousData?.averageRatings} // the rating value
              size={30} // the size of the star
              edit={false} // disable user interaction
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
            />
            <span className="form-heading">
              {previousData?.averageRatings}/5
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="form-body">
          <div className="form-content">
            <div className="content-right">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {cat?.map((item, index) => {
                  return (
                    <span className="badge mrs mts" key={index}>
                      {item || "Add Category"}
                    </span>
                  );
                })}
                <span
                  className="badge mrs mts pointer"
                  onClick={() => setPopup(true)}
                >
                  Add Category
                </span>
              </div>

              <div>
                <input
                  required
                  type="text"
                  placeholder="Product Title"
                  className="field"
                  name="title"
                  id="title"
                  value={product.title}
                  onChange={handleProductChange}
                />
              </div>
              {/* <div className="form-row"> */}
              <div className="form-row">
                <input
                  required
                  type="text"
                  name="product_id"
                  id="product_id"
                  value={product.product_id}
                  onChange={handleProductChange}
                  placeholder="Product ID"
                  className="field"
                />
                <input
                  type="text"
                  name="barcode"
                  id="barcode"
                  value={product.barcode}
                  onChange={handleProductChange}
                  placeholder="Barcode"
                  className="field"
                />
              </div>
              <div className="form-row">
                <input
                  type="number"
                  name="price"
                  id="price"
                  placeholder="Price(PKR)"
                  value={product.price}
                  onChange={handleProductChange}
                  className="field"
                />
                <input
                  type="string"
                  name="stock"
                  id="stock"
                  placeholder="Stock"
                  value={product.stock}
                  onChange={handleProductChange}
                  className="field"
                />
              </div>
              <div className="form-row">
                <input
                  type="text"
                  name="color"
                  id="color"
                  value={product.color}
                  onChange={handleProductChange}
                  placeholder="Color"
                  className="field"
                />
                <input
                  type="text"
                  name="weight"
                  id="weight"
                  value={product.weight}
                  onChange={handleProductChange}
                  placeholder="Weight"
                  className="field"
                />
              </div>
              <div>
                <input
                  type="text"
                  name="dimension"
                  id="dimension"
                  placeholder="Dimensions"
                  value={product.dimension}
                  onChange={handleProductChange}
                  className="field"
                />
              </div>
              <div>
                <input
                  required
                  type="text"
                  name="sku_number"
                  id="sku_number"
                  placeholder="SKU Number"
                  value={product.sku_number}
                  onChange={handleProductChange}
                  className="field"
                />
              </div>
            </div>
            <div className="content-left">
              <div>
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Product short Description"
                  value={product.description}
                  onChange={handleProductChange}
                  className="field description"
                />
              </div>
              <div>
                <span className="form-headings">Discount</span>
              </div>
              <div>
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={discounts.title}
                  onChange={handleDisocunt}
                  placeholder="Discount Title"
                  className="field"
                />
              </div>
              <div className="form-row">
                <Select
                  styles={{
                    control: customColor,
                  }}
                  defaultValue={
                    discounts.type
                      ? {
                          label: discounts.type,
                          value: discounts.type,
                        }
                      : ""
                  }
                  options={discountTypes}
                  placeholder={"Discount Type"}
                  className="select-in"
                  id="type"
                  name="type"
                  onChange={handleDisocunt}
                />
              </div>
              <div>
                <input
                  type="number"
                  name="rate"
                  id="rate"
                  value={discounts.rate}
                  onChange={handleDisocunt}
                  placeholder="Discount Rate"
                  className="field"
                />
              </div>
              <div className="form-row">
                <div className="dis-date">
                  <span className="dis-label">Discount Start Date</span>
                  <input
                    type="date"
                    name="startDateTime"
                    id="startDateTime"
                    defaultValue={
                      discounts?.startDateTime
                        ? format(
                            new Date(discounts?.startDateTime),
                            "yyyy-MM-dd"
                          )
                        : ""
                    }
                    // value={discounts.startDateTime}
                    onChange={handleDisocunt}
                    placeholder="Discount Start Date"
                    className="field"
                  />
                </div>

                <div className="dis-date">
                  <span className="dis-label">Discount End Date</span>
                  <input
                    type="date"
                    name="endDateTime"
                    id="endDateTime"
                    defaultValue={
                      discounts?.endDateTime
                        ? format(new Date(discounts?.endDateTime), "yyyy-MM-dd")
                        : ""
                    }
                    // value={discounts.endDateTime}
                    onChange={handleDisocunt}
                    // placeholder="Discount End Date"
                    className="field"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-image">
            <div className="image-sec">
              <div className="imageicon">
                <img
                  src={
                    picture
                      ? URL.createObjectURL(picture)
                      : product.img_id
                      ? product?.img_id.substring(0, 4) === "http"
                        ? product.img_id
                        : "https://petsetgostorage.blob.core.windows.net/petsetgo-u2/" +
                          product.img_id
                      : imageicon
                  }
                  alt="image"
                  className="image"
                />
              </div>
              <div className="imagehead">
                <label htmlFor="img_id" style={{ cursor: "pointer" }}>
                  Upload image and videos
                </label>
                <input
                  type="file"
                  name="img_id"
                  id="img_id"
                  onChange={handlePicture}
                  className="file"
                />
              </div>
              <span className="imagepg">
                Upload a cover image for your store.
                <br />
                File Format jpeg, png Recommened Size 600x600 (1:1)
              </span>
            </div>
          </div>
        </div>
        <div className="form-footer">
          <div className="linked">
            <span className="form-headings">Linked Products</span>
            <div>
              <Select
                key={linkP.length}
                styles={{
                  control: customColor,
                }}
                isMulti
                defaultValue={linkP ? linkP : ""}
                options={products}
                id="linked_product"
                name="linked_product"
                onChange={handleArrayEvent}
                placeholder={"Linked Product"}
                className="link-field"
              ></Select>
            </div>
            <div className="linked">
              <span className="form-headings">Tax Information</span>
              <div className="form-row tax-fields">
                <Select
                  styles={{
                    control: customColor,
                  }}
                  defaultValue={taxx}
                  options={tax}
                  placeholder="Tax"
                  className=" tax-field"
                  name="tax_status"
                  id="tax_status"
                  onChange={handleProductChange}
                />
                <Select
                  styles={{
                    control: customColor,
                  }}
                  defaultValue={tax_classs}
                  options={tax_class}
                  placeholder="Tax Class"
                  className=" tax-field"
                  name="tax_class"
                  id="tax_class"
                  onChange={handleProductChange}
                  // onChange={handleTax}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}
