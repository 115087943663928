import React from "react";
import "../styles/calander.css";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { format } from "date-fns";
import { tr } from "date-fns/locale";
import Slotspop from "./Slotspop";
import { url } from "../utils/urls.js";

export default function Slots(props) {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("authToken")
      ? localStorage.getItem("authToken" + "")
      : ""
  );
  const [session_id, setSession_id] = useState(
    localStorage.getItem("session_id")
      ? localStorage.getItem("session_id" + "")
      : ""
  );
  const [storeid, setStoreid] = useState("");
  const [data, setData] = useState([]);
  const [slots, setSlots] = useState([]);
  const [popup, setPopup] = useState(false);
  const fetchdata = async () => {
    const response = await axios
      .get(
        // `${url}/v1/order/dayboarding/get/calender?store_id=${storeid}`,
        `${url}/v1/store/slots/calender?store_id=${props.storeId}`,
        {
          headers: { authtoken: authToken, sessionid: session_id },
        }
      )
      .catch((err) => console.log(err));
    // console.log(response);
    const resdata = response.data.days;
    console.log(resdata);
    setData(resdata);
    // console.log(data);
  };
  useEffect(() => {
    if (props.storeId) {
      setStoreid(props.storeId);
    }
    fetchdata();
    props.setName("Slots");
  }, [props.storeId]);

  return (
    <div className="content">
      <div className="slots">
        {data.map((item, index) =>
          item.data ? (
            <div key={index} className="Slot-headers">
              <div>{item.date}</div>
              <div>{item.day}</div>
              {item.data?.slots?.map((item, index) => (
                <div
                  key={index}
                  className="slot"
                  onClick={(e) => console.log(item)}
                >
                  {item.start}
                </div>
              ))}
            </div>
          ) : (
            ""
          )
        )}
        <Slotspop trigger={popup} setTrigger={setPopup} />
      </div>
    </div>
  );
}
